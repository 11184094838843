@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

/*
  fix for antd icons and tailwind
  https://github.com/ant-design/ant-design/issues/13074
*/
svg {
  vertical-align: baseline;
}
